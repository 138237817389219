import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { UOPX_PHONE_NUMBER } from '../../modules/admissions/constants';
import {
  ReactComponent as SupportIcon,
} from '../../modules/admissions/components/icons/support.svg';
import PhoneHelp from './we-are-here-to-help/PhoneHelp';
import ChatHelp from './ChatHelp';
import styles from './styles';
import { triggerAlloyTrack } from '../../modules/vendor/alloy/AlloyUpdater';

export default function NeedHelp({
  heading,
  personId,
  message,
}) {
  const classes = styles();
  const styleOverride = {
    paddingTop: 0,
    marginTop: '1.5rem',
    marginBottom: 0,
  };
  const title = 'Need help?';
  const text = message;

  const handleCallClick = () => {
    triggerAlloyTrack('ContactClick', {
      componentName: 'Need Help',
      text: UOPX_PHONE_NUMBER,
      interactLocation: 'Content',
    }, { personId });
  };

  const call = () => (
    <Grid
      container
      className={classes.gCall}
    >
      <Grid item>
        <SupportIcon className={classes.helpSupportIcon} />
      </Grid>
      <PhoneHelp
        id="needhelp_phone"
        onClickCallback={handleCallClick}
        ariaLabel="Call enrollment representative at"
        styleOverride={styleOverride}
      />
    </Grid>
  );

  return (
    <div className={clsx(classes.root, 'needHelp')}>
      <Typography variant={heading} className={classes.headingLead}>
        {title}
      </Typography>
      <Typography id="need-help-text" className={classes.text}>
        {text}
      </Typography>
      <ul className={classes.gChatContact} aria-describedby="need-help-text">
        <li>
          {call()}
        </li>
        <li>
          <ChatHelp />
        </li>
      </ul>
    </div>
  );
}

NeedHelp.defaultProps = {
  heading: 'h3',
  personId: '',
  message: 'If you have questions about our programs, call or chat with one of our knowledgeable enrollment representatives.',
};

NeedHelp.propTypes = {
  heading: PropTypes.string,
  personId: PropTypes.string,
  message: PropTypes.string,
};
